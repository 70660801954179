<template>
  <div>
    <validation-observer ref="VFormStok">
      <div class="row mb-3">
        <div class="col-md-12">
          <h6 class="font-weight-bold">Stok Tersedia di Gudang</h6>
          <table class="table table-bordered table-sm mt-2">
            <tr>
              <td style="width: 290px;">Nama Gudang</td>
              <td>Min</td>
              <td>Max</td>
              <td>Buffer</td>
              <td>Stok Tersedia</td>
              <td>Stok On Order</td>
              <td>Aksi</td>
            </tr>
            <tr v-for="(v,k) in (dataStokGudang||[])" :key="k">
              <td>
                <b-form-group>
                  <v-select placeholder=" -- Pilih Gudang -- " v-model="dataStokGudang[k].mais_gudang_ruang_id" :options="getMasterDynamic(dataStokGudang,mLogistikGudang,k,'mais_gudang_ruang_id')" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </b-form-group>

                <VValidate 
                  :name="`Gudang ${k+1}`" 
                  v-model="dataStokGudang[k].mais_gudang_ruang_id" 
                  :rules="{required: 1}"
                />
              </td>
              <td>
                <b-form-group>
                  <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokGudang[k]['mais_min']"/>
                </b-form-group>
                <VValidate 
                  :name="`Min ${k+1}`" 
                  v-model="dataStokGudang[k].mais_min" 
                  :rules="{required: 1}"
                />
              </td>
              <td>
                <b-form-group>
                  <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokGudang[k]['mais_max']"/>
                </b-form-group>
                <VValidate 
                  :name="`Max ${k+1}`" 
                  v-model="dataStokGudang[k].mais_max" 
                  :rules="{required: 1}"
                />
              </td>
              <td>
                <b-form-group>
                  <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokGudang[k]['mais_buffer']"/>
                </b-form-group>
                <VValidate 
                  :name="`Buffer ${k+1}`" 
                  v-model="dataStokGudang[k].mais_buffer" 
                  :rules="{required: 1}"
                />
              </td>
              <td>
                <template v-if="isParent.moduleRole('full_access')">
                  <b-form-group>
                    <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokGudang[k]['mais_tersedia']"/>
                  </b-form-group>
                  <VValidate 
                    :name="`Tersedia ${k+1}`" 
                    v-model="dataStokGudang[k].mais_tersedia" 
                    :rules="{required: 1}"
                  />
                </template>
                <span v-else>
                  {{v.mais_tersedia||"-"}}
                </span>
              </td>
              <td>
                <template v-if="isParent.moduleRole('full_access')">
                  <b-form-group>
                    <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokGudang[k]['mais_on_order']"/>
                  </b-form-group>
                  <VValidate 
                    :name="`On Order ${k+1}`" 
                    v-model="dataStokGudang[k].mais_on_order" 
                    :rules="{required: 1}"
                  />
                </template>
                <span v-else>
                  {{v.mais_on_order||"-"}}
                </span>
              </td>
              <td>
                <a href="javascript:;" class="list-icons-item"
                @click="deleteStok(k, v, 'G')"
                data-toggle="tooltip" data-placement="top" title="Delete"><i
                  class="icon-bin"></i></a>
              </td>
            </tr>
            <tr>
              <td colspan="99" class="text-center">
                <button type="button" class="btn btn-info btn-sm" @click.prevent="addStok('G')">Tambahkan Data</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <h6 class="font-weight-bold">Stok Tersedia di Unit</h6>
          <table class="table table-bordered table-sm mt-2">
            <tr>
              <td style="width: 290px;">Nama Unit</td>
              <td>Min</td>
              <td>Max</td>
              <td>Buffer</td>
              <td>Stok Tersedia</td>
              <td>Stok On Order</td>
              <td>Aksi</td>
            </tr>
            <tr v-for="(v,k) in (dataStokUnit||[])" :key="k">
              <td>
                <b-form-group>
                  <v-select placeholder=" -- Pilih Unit -- " v-model="dataStokUnit[k].mais_unit_ruang_id" :options="getMasterDynamic(dataStokUnit,mLogistikUnit,k,'mais_unit_ruang_id')" label="text" :clearable="true" :reduce="v=>v.value"></v-select>
                </b-form-group>

                <VValidate 
                  :name="`Unit ${k+1}`" 
                  v-model="dataStokUnit[k].mais_unit_ruang_id" 
                  :rules="{required: 1}"
                />
              </td>
              <td>
                <b-form-group>
                  <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokUnit[k]['mais_min']"/>
                </b-form-group>
                <VValidate 
                  :name="`Min ${k+1}`" 
                  v-model="dataStokUnit[k].mais_min" 
                  :rules="{required: 1}"
                />
              </td>
              <td>
                <b-form-group>
                  <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokUnit[k]['mais_max']"/>
                </b-form-group>
                <VValidate 
                  :name="`Max ${k+1}`" 
                  v-model="dataStokUnit[k].mais_max" 
                  :rules="{required: 1}"
                />
              </td>
              <td>
                <b-form-group>
                  <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokUnit[k]['mais_buffer']"/>
                </b-form-group>
                <VValidate 
                  :name="`Buffer ${k+1}`" 
                  v-model="dataStokUnit[k].mais_buffer" 
                  :rules="{required: 1}"
                />
              </td>
              <td>
                <template v-if="isParent.moduleRole('full_access')">
                  <b-form-group>
                    <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokUnit[k]['mais_tersedia']"/>
                  </b-form-group>
                  <VValidate 
                    :name="`Tersedia ${k+1}`" 
                    v-model="dataStokUnit[k].mais_tersedia" 
                    :rules="{required: 1}"
                  />
                </template>
                <span v-else>
                  {{v.mais_tersedia||"-"}}
                </span>
              </td>
              <td>
                <template v-if="isParent.moduleRole('full_access')">
                  <b-form-group>
                    <b-form-input :formatter="isParent.numberOnly" type="text" class="form-control" v-model="dataStokUnit[k]['mais_on_order']"/>
                  </b-form-group>
                  <VValidate 
                    :name="`On Order ${k+1}`" 
                    v-model="dataStokUnit[k].mais_on_order" 
                    :rules="{required: 1}"
                  />
                </template>
                <span v-else>
                  {{v.mais_on_order||"-"}}
                </span>
              </td>
              <td>
                <a href="javascript:;" class="list-icons-item"
                @click="deleteStok(k, v, 'U')"
                data-toggle="tooltip" data-placement="top" title="Delete"><i
                  class="icon-bin"></i></a>
              </td>
            </tr>
            <tr>
              <td colspan="99" class="text-center">
                <button type="button" class="btn btn-info btn-sm" @click.prevent="addStok('U')">Tambahkan Data</button>
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-md-12 text-right" >
          <button type="submit" @click="simpanData()" class="btn btn-success btn-labeled btn-labeled-left"><b><i
                class="icon-checkmark"></i></b> Simpan</button>
        </div>
      </div>
    </validation-observer>
  </div>
</template>



<script>
// import GlobalVue from '@/libs/Global.vue'
const _ = global._
import Gen from '@/libs/Gen.js'

export default{
  // extends: GlobalVue,
  data() {
    return {
      dataStokGudang: [],
      dataStokUnit: [], 
      
      mLogistikGudang : [],
      mLogistikUnit : [],
    }
  },
  computed:{
    isParent(){
      return this.$parent.$parent.$parent
    }
  },
  methods: {
    getMasterDynamic(data, master, k, val) {
      const sldMenu = _.map(_.filter(data || [], (v2, k2) => {
        return k2 != k
      }), v2 => {
        return v2[val]
      })
      return _.filter(master, v2 => {
        return sldMenu.indexOf(v2.value) <= -1
      })
    },

    toValidate(val){
      return {...val}
    },
    getDataStok(){
      this.isParent.loadingOverlay = true
      Gen.apiRest('/do/' + this.isParent.modulePage, {
        data: {
          type: 'data-stok',
          id: this.isParent.rowEdit.mai_id,
        }
      }, 'POST').then(res => {
        this.dataStokGudang = res.data.dataStokGudang
        this.dataStokUnit = res.data.dataStokUnit
        this.mLogistikGudang = res.data.mLogistikGudang
        this.mLogistikUnit = res.data.mLogistikUnit

        this.isParent.loadingOverlay = false
      })
    },
    addStok(type){
      this.isParent.loadingOverlay = true
      let data = {
        type: 'add-stok',
        mais_type_stok : type,
        mais_gudang_ruang_id : null,
        mais_unit_ruang_id : null,
        mais_barang_id : this.isParent.rowEdit.mai_id,
        mais_tersedia : null,
        mais_min : null,
        mais_max : null,
        mais_buffer : null,
      }
      Gen.apiRest('/do/' + this.isParent.modulePage, {
        data: data
      }, 'POST').then(res => {
        this.isParent.loadingOverlay = false    
        if(type == 'G'){
          this.dataStokGudang.push(res.data.row)
        }else{
          this.dataStokUnit.push(res.data.row)
        }
      })

    },
    
    autoSaveData: _.debounce(function (data) {
      let dataPost = {
        type : 'auto-save-stok',
        data : data
      }
      Gen.apiRest(
        "/do/" +  this.isParent.modulePage, {
          data: dataPost
        },
        "POST"
      )
    }, 1000), 

    deleteStok(k, v, type){
      this.$swal({
        icon: 'warning',
        title: 'Pastikan data yang anda hapus adalah data yang benar, Data yang dihapus akan mempengaruhi jumlah stok',
        showCancelButton: true,
        confirmButtonText: 'Ya',
        cancelButtonText: 'Tidak, kembali'
      }).then(result => {
        if(result.value){
          let dataPost = {
            type : 'remove-stok',
            mais_id : v.mais_id
          }
          Gen.apiRest(
            "/do/" +  this.isParent.modulePage, {
              data: dataPost
            },
            "POST"
          ).then(() => {
            this.isParent.loadingOverlay = false
            if(type == 'G'){
              this.dataStokGudang.splice(k,1)
            }else{
              this.dataStokUnit.splice(k,1)
            }
          })
        }
      })
    },
    simpanData(){
      
      this.$refs['VFormStok'].validate().then(success=>{
        if(success){
          this.$swal({
            icon: 'warning',
            title: 'Apakah Anda yakin akan mengubah data stok',
            showCancelButton: true,
            confirmButtonText: 'Ya',
            cancelButtonText: 'Tidak, kembali'
          }).then(result => {
            if(result.value){
              this.isParent.loadingOverlay = true

              setTimeout(() => {
                this.isParent.loadingOverlay = false              
                this.isParent.showStok = false
                return this.$swal({
                  icon: 'success',
                  title: 'Data Berhasil Disimpan',
                })
              }, 100)
              
            }
          })
        }
      })
    },
  },
  mounted() {
    this.getDataStok()
  },
  watch: {
    'dataStokGudang': {
      handler(v) {
        this.autoSaveData(v)
      },
      deep: true
    },
    'dataStokUnit': {
      handler(v) {
        this.autoSaveData(v)
      },
      deep: true
    }
  }
}
</script>